<template>
    <b-sidebar
        id="sidebar"
        :visible="isSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        no-close-on-backdrop
        right
        @shown="loadData"
        @hidden="resetForm"
        @change="(val) => $emit('update:is-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0 text-capitalize">
                    {{ titleForm }}
                </h5>
                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
                    <!-- Name -->
                    <validation-provider
                        #default="validationContext"
                        name="title"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('luxriot.user')"
                            label-for="title"
                        >
                            <b-form-input
                                id="title"
                                v-model="itemData.title"
                                :placeholder="$t('luxriot.placeholderTitle')"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Password -->
                    <validation-provider
                        #default="validationContext"
                        name="password"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('luxriot.password')"
                            label-for="password"
                        >
                            <b-form-input
                                id="password"
                                type="password"
                                v-model="itemData.password"
                                :placeholder="$t('luxriot.placeholderPassword')"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Link -->
                    <validation-provider
                        #default="validationContext"
                        name="link"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('luxriot.link')"
                            label-for="link"
                        >
                            <b-form-input
                                id="link"
                                v-model="itemData.link"
                                :placeholder="$t('luxriot.placeholderLink')"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <!-- Tenant -->
                    <validation-provider
                        #default="validationContext"
                        name="tenants"
                        rules="required"
                    >
                        <b-form-group
                            :label="$t('luxriot.tenant')"
                            label-for="tenants"
                            :state="getValidationState(validationContext)"
                        >
                            <v-select
                                label="name"
                                v-model="itemData.tenants"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :placeholder="$t('luxriot.placeholderTenants')"
                                :options="tenants"
                                :clearable="false"
                                :multiple="true"
                                input-id="tenants"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2 text-capitalize"
                            type="submit"
                        >
                            {{ isAdd ? $t('actions.add') :$t('actions.edit') }}
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                            class="text-capitalize"
                            >
                                {{$t('actions.cancel')}}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import axios from '@/libs/axios'

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        BFormCheckbox,

        // Form Validation
        ValidationProvider,
        ValidationObserver
    },
    directives: {
        Ripple,
    },
    model: {
        prop: 'isSidebarActive',
        event: 'update:is-sidebar-active',
    },
    props: {
        isSidebarActive: {
            type: Boolean,
            required: true,
        },
        itemEdit: {
            type: Object,
            required: false,
        },
        isAdd: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            positive,
        };
    },
    setup(props, { emit }) {
        const blankData = {
            title: null,
            password: null,
            link: null,
            enabled: true,
            tenants: []
        };

        onMounted(async () => await listTenants());

        //Composables
        const toast = useToast()
        const { t } = useI18nUtils()

        //Data
        const itemData = ref(JSON.parse(JSON.stringify(blankData)))

        const tenants  = ref([]);

        //Computed
        const titleForm = computed(() => props.isAdd ? t('luxriot.addMasterAccount') : t('luxriot.editMasterAccount'))

        //Methods
        const contentToast = (message,type) => toast({
            component: ToastificationContent,
            props: {
                title: message,
                icon: 'CheckIcon',
                variant: type
            }
        })

        const listTenants = async () => {
            let { data } = await axios.get('/v1/services/tenants/list')
            let { data:dataApi, success } = data

            if (!success) {
                tenants.value = []
            }

            tenants.value = dataApi
        }

        const onSubmit = async () => {
            try {
                let masterAccountRequest = {
                    masterAccount: itemData.value
                }

                let { data } = (itemData.value.id)
                ? await axios.put(`/v1/services/luxriot/master-accounts/${itemData.value.id}`, masterAccountRequest)
                : await axios.post('/v1/services/luxriot/master-accounts', masterAccountRequest)

                let { message } = data

                contentToast(message,'success')
                emit('refetch-data')
                emit('update:is-sidebar-active', false);
            } catch (error) {
                let { message } = error.response.data

                contentToast(message,'danger')
            }
        }

        const resetData = () => {
            itemData.value = JSON.parse(JSON.stringify(blankData))
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

        const loadData = () => {
            if (!props.isAdd) {
                itemData.value = props.itemEdit;
            }
        };

        return {
            itemData,
            onSubmit,

            refFormObserver,
            getValidationState,
            loadData,
            resetForm,
            tenants,

            titleForm
        };
    }
};
</script>
